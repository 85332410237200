import { Flex, Box, Text, Image } from "rebass/styled-components";
import Icon from "../common/Icon";
import useTranslation from "next-translate/useTranslation";
import styles from "../../styles/mobileAppStyle";
import theme from "../../styles/theme";
import { ColRowType } from "../../types";
import { useContext } from "react";
import { LocaleContext } from "../locale";

interface Props {
  type?: ColRowType;
  isMobile: boolean;
}

function MobileApp(props: Props) {
  const { type = "row", isMobile } = props;
  const { t } = useTranslation("common");
  const { locale } = useContext(LocaleContext);

  const dir = ["column", "column", type];
  const blockWidth = type === "row" ? theme.fullToHalf : { width: "100%" };
  const styleLeft = type === "row" ? styles.phoneLeft : styles.phoneColLeft;
  const styleRight = type === "row" ? styles.phoneRight : styles.phoneColRight;

  const icons = ["download", "search", "support", "photo"];
  const info = [...Array(icons.length)].map((_, i) => {
    return {
      iconName: icons[i],
      title: t(`appTitle${i + 1}`),
      desc: t(`appDesc${i + 1}`),
    };
  });

  // Rendering
  const renderLink = (href: string, imgSrc: string, imgAlt: string) => {
    return (
      <a href={href} target="_blank" rel="noreferrer noopener">
        <Image alt={imgAlt} src={imgSrc} />
      </a>
    );
  };

  return (
    <Flex
      sx={{
        ...styles.container,
        flexDirection: dir,
        gap: "32px",
      }}
    >
      <Flex sx={{ flexDirection: "column", ...blockWidth, gap: "24px" }}>
        {info.map((block, index) => (
          <Flex key={index} sx={{ gap: "16px" }}>
            <Flex sx={styles.iconBox}>
              <Icon name={block.iconName} color="inuOrange" fontSize={7} />
            </Flex>

            <Flex sx={{ flexDirection: "column", flex: 1 }}>
              <Text as="h4" sx={styles.title}>
                {block.title}
              </Text>

              <Text as="h4" sx={{ mt: "4px", ...styles.desc }}>
                {block.desc}
              </Text>
            </Flex>
          </Flex>
        ))}

        <Flex width="100%" mt={1}>
          {!isMobile && (
            <Flex sx={styles.qr} mr={4}>
              <Image
                alt="QR code"
                src="/blog/images/mobile-qr.png"
                width="128px"
                height="128px"
              />
            </Flex>
          )}

          <Flex
            sx={{ flexDirection: isMobile ? "row" : "column", gap: "16px" }}
          >
            <Box width="200px">
              {renderLink(
                "https://play.google.com/store/apps/details?id=com.gudog.app",
                `/blog/images/locale/${locale}/google_play.svg`,
                "Gudog - Google Play"
              )}
            </Box>

            <Box width="200px">
              {renderLink(
                "https://apps.apple.com/de/app/gudog/id964470204",
                `/blog/images/locale/${locale}/app_store.svg`,
                "Gudog - AppStore"
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex sx={{ ...blockWidth, ...styles.phonesBox }}>
        <Image
          alt="App demo 2"
          src={`/blog/images/locale/${locale}/mobile_2.png`}
          sx={styleLeft}
        />
        <Image
          alt="App demo 1"
          src={`/blog/images/locale/${locale}/mobile_1.png`}
          sx={styleRight}
        />
      </Flex>
    </Flex>
  );
}

export default MobileApp;
