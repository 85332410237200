import dynamic from "next/dynamic";
import { useMemo } from "react";
import theme from "../../styles/theme";

interface Props {
  name: string;
  [x: string]: any;
}

const Icon = (props: Props) => {
  const { name, color, fontSize, sx, ...rest } = props;

  const iconColor =
    theme.colors[(color as keyof typeof theme.colors) || "labradorBlack"];

  const IconComponent: any = useMemo(
    () =>
      dynamic(() => import(`../../public/icons/${name}.svg`), {
        ssr: true,
      }),
    [name]
  );
  return (
    <IconComponent
      color={iconColor}
      {...rest}
      fontSize={theme.fontSizes[fontSize] || "1em"}
    />
  );
};
export default Icon;
