/* With this component we avoid sending additional requests if the user
requests the same page he's already in.
Useful in Pagination */

import Link from "next/link";
import { useRouter } from "next/router";
import { Box } from "rebass/styled-components";
import styled from "styled-components";

interface Props {
  href: string;
  disabled?: boolean;
  children: JSX.Element;
  prefetch?: boolean;
}

const AnchorLink = styled.a`
  text-decoration: none !important;
  color: inherit;
`;

const CustomLink = (props: Props) => {
  const { href, disabled = false, children, ...rest } = props;
  const router = useRouter();
  const isCurrentPage = router.pathname === href;

  return isCurrentPage || disabled ? (
    <Box>{children}</Box>
  ) : (
    <Link href={href} passHref {...rest}>
      <AnchorLink>{children}</AnchorLink>
    </Link>
  );
};

export default CustomLink;
