const styles = {
  container: {
    width: "100%",
    bg: "inuOrange",
    px: ["16px", "16px", "24px"],
    py: "24px",
    borderRadius: "8px",
  },
  iconBox: {
    justifyContent: "center",
    alignItems: "center",
    width: "80px",
    height: "80px",
    border: "1px solid",
    borderColor: "samoyedWhite",
    borderRadius: "24px",
    bg: "samoyedWhite",
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
    color: "samoyedWhite",
  },
  desc: {
    fontSize: "18px",
    fontWeight: 400,
    color: "samoyedWhite",
  },
  qr: {
    width: "144px",
    height: "144px",
    bg: "samoyedWhite",
    justifyContent: "center",
    alignItems: "center",
  },
  phonesBox: {
    alignItems: "center",
    textAlign: "right",
    position: "relative",
  },
  phoneLeft: {
    position: ["relative", "relative", "absolute", "absolute"],
    right: ["-60px", "-60px", "50%", "40%"],
    width: ["38%", "38%", "40%", "40%"],
  },
  phoneRight: {
    position: ["relative", "relative", "absolute", "absolute"],
    right: ["-14px", "-14px", "50px", "50px"],
    width: ["43%", "43%", "50%", "45%"],
  },
  phoneColLeft: {
    position: "relative",
    right: ["-60px", "-60px", "-45px", "-45px"],
    width: ["38%", "38%", "85%", "85%"],
  },
  phoneColRight: {
    position: "relative",
    right: ["-14px", "-14px", "20px", "20px"],
    width: ["43%", "43%", "100%", "100%"],
  },
};
export default styles;
