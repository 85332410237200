const styles = {
  container: {
    default: {
      position: "relative",
      height: 96,
      flexWrap: "wrap",
    },
    mobile: {
      height: "inherent",
    },
  },
  children: {
    width: "100%",
    zIndex: 2,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  heroTitle: {
    container: {
      justifyContent: ["space-between", "space-between", "center"],
      alignItems: "center",
      py: [2, 2, 4],
      px: 2,
    },
    stamp: {
      alignItems: ["flex-start", "flex-start", "center"],
    },
    line: {
      alignItems: "center",
    },
    icon: {
      justifyContent: "center",
      m: "auto",
    },
    title: {
      color: "samoyedWhite",
      fontSize: ["25px", "25px", "120px"],
      fontWeight: 400,
      pl: 0,
      letterSpacing: [0, "-3px"],
    },
    subTitle: {
      color: "samoyedWhite",
      fontSize: ["12px", "12px", "30px"],
      lineHeight: ["16px", "16px", "36px"],
      fontWeight: [400, 400, 500],
      pt: ["4px", "4px", "12px"],
    },
    button: {
      px: "8px",
      pt: "16px",
      pb: "10px",
    },
  },
  coverImage: {
    default: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "black",
    },
    darken: {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "black",
    },
  },
};

export default styles;
