import { useState } from "react";
import useTranslation from "next-translate/useTranslation";

import NavigationMenu from "./NavigationMenu/NavigationMenu";
import SEO from "./SEO";
import Hero from "./Hero/Hero";
import HeroTitle from "./Hero/HeroTitle";
import useIsMobile from "../hooks/useIsMobile";
import Container from "./Container";
import NavigationMenuMobile from "./NavigationMenu/NavigationMenuMobile";
import { MenuLinksType } from "../types";
import { useGetMenusQuery } from "../generated/graphql";
import CategoriesHeader from "./NavigationMenu/CategoriesMenu";
import Footer from "./footer/Footer";
import useRoutesSupplier from "../hooks/useRoutesSupplier";

function Layout({
  children,
  seoTitle,
  seoDescription,
}: {
  children: JSX.Element;
  seoTitle?: string;
  seoDescription?: string;
}) {
  const { t } = useTranslation("common");

  const isMobile = useIsMobile();
  const { getRoute } = useRoutesSupplier();

  const [menuClicked, setMenuClicked] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  const { data: menusData } = useGetMenusQuery();
  const menu = menusData?.menus?.nodes[0].menuItems?.nodes || [];

  const routesMenu: MenuLinksType = {
    howItWorks: getRoute("howitworks"),
    ourServices: getRoute("ourservices"),
    becomeASitter: getRoute("recruitingsitters"),
    signup: getRoute("signup"),
    login: getRoute("login"),
  };

  const handleMenuClicked = () => {
    setMenuClicked(!menuClicked);
    setSearchClicked(false);
  };

  const handleSearchClick = () => {
    setSearchClicked(!searchClicked);
    setMenuClicked(false);
  };

  const renderHeroTitle = () => {
    return (
      <HeroTitle
        title={t("siteTitle")}
        subTitle={t("siteDescription")}
        isMobile={isMobile}
        onMenuClick={handleMenuClicked}
        onSearchClick={handleSearchClick}
      />
    );
  };

  return (
    <>
      <SEO
        title={seoTitle ?? t("siteTitle")}
        description={seoDescription ?? t("siteDescription")}
      />

      {!isMobile && <NavigationMenu menuLinks={routesMenu} />}

      {!isMobile ? (
        <Hero imgsrc="/blog/images/header_desktop.webp" darken={0.5}>
          {renderHeroTitle()}
        </Hero>
      ) : (
        renderHeroTitle()
      )}

      {isMobile && (
        <NavigationMenuMobile
          categories={menu}
          menuLinks={routesMenu}
          menuVisible={menuClicked}
          searchVisible={searchClicked}
          onSearch={handleSearchClick}
        />
      )}

      {!isMobile && <CategoriesHeader categories={menu} />}

      <Container>{children}</Container>

      <Footer isMobile={isMobile} displayAreas />
    </>
  );
}

export default Layout;
