import Image from "next/image";
import { Box } from "rebass/styled-components";

interface Props {
  url: string;
  altText?: string;
  shouldLazyLoad?: boolean;
  width?: string;
  height?: string;
  maxHeight?: string;
  aspectRatio?: string;
  priority?: boolean;
}

export default function FeaturedImage(props: Props) {
  const {
    url,
    altText = "",
    shouldLazyLoad = true,
    width = "100%",
    height,
    aspectRatio,
    priority = false,
    maxHeight,
  } = props;

  if (!aspectRatio) {
    return <img width={width} height={height} src={url} alt={altText} />;
  }

  const ar = !height ? aspectRatio : "1";

  return (
    <Box
      maxHeight={maxHeight || "600px"}
      width={width}
      height={height}
      sx={{ position: "relative", aspectRatio: ar }}
    >
      <Image
        src={url}
        alt={altText}
        loading={shouldLazyLoad ? "lazy" : "eager"}
        objectFit="cover"
        layout="fill"
        priority={priority}
      />
    </Box>
  );
}
