import { Box } from "rebass/styled-components";
import styles from "../../styles/navigationMenuStyle";

interface Props {
  children: React.ReactNode;
  [key: string]: any;
}

const NavItem = ({ children, ...rest }: Props) => {
  return (
    <Box as="li" sx={{ ...styles.navItem, ...rest }}>
      {children}
    </Box>
  );
};

export default NavItem;
