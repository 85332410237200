const styles = {
  readMore: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    textDecoration: "underline !important",
    color: "springGreen",
    marginRight: "8px",
  },
  category: {
    post: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 600,
      textDecoration: "underline !important",
      color: "prusianBlue",
      marginRight: "8px",
    },
    article: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 600,
      textDecoration: "underline !important",
      color: "springGreen",
      marginRight: "8px",
    },
  },
  sidePostDesc: {
    "> p": {
      margin: "0 !important",
      padding: "0 !important",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": "2" /* number of lines to show */,
      "line-clamp": "2",
      "-webkit-box-orient": "vertical",
    },
  },
};

export default styles;
