const styles = {
  container: {
    p: "24px",
    backgroundColor: "aquamarine",
    borderRadius: "8px",
    textAlign: "left",
  },
  subtitle: {
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "samoyedWhite",
    lineHeight: 1.6,
  },
  inputBox: {
    width: ["100%", "100%", "50%"],
    position: "relative",
  },
  input: {
    bg: "samoyedWhite",
    m: 0,
    py: 0,
    px: 2,
    height: "48px",
    lineHeight: "100%",
    fontSize: 2,
    border: "1px solid",
    borderColor: "aquamarine",
    width: "100%",
    transition: "box-shadow 0.2s ease 0s",
    ":focus": {
      borderColor: "springGreen",
      outline: "none",
      boxShadow: "inputFocus",
      "~i": {
        opacity: 1,
      },
    },
    ":disabled": {
      backgroundColor: "lightGray",
    },
    "::-webkit-inner-spin-button,::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  button: {
    width: ["100%", "100%", "100%"],
    height: "min-content",
  },
  checkboxText: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "samoyedWhite",
  },
};

export default styles;
