import TagManager from "./react-gtm";

/* Due to security reasons, we cannot interact with a youtube player
on an iframe, that's why we don't add it as an event to track */
export type BlogEvent =
  | "find_sitter_clicked"
  | "signup_clicked"
  | "know_gudog_find_sitter_clicked"
  | "search_sent"
  | "banner_subscribe_clicked"
  | "popup_subscribe_clicked";

// Tracks a normal event
function trackEvent(
  eventName: BlogEvent,
  eventParams?: any,
  ecommerce?: any
): void {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      eventParams,
      ecommerce,
    },
  });
}

export { trackEvent };
