import { Flex, Text, Box } from "rebass/styled-components";
import theme from "../../styles/theme";
import Button from "../common/Button";
import useTranslation from "next-translate/useTranslation";
import styles from "../../styles/infoBlockStyle";
import { ColRowType } from "../../types";
import { trackEvent } from "../../lib/event-tracking";
import useRoutesSupplier from "../../hooks/useRoutesSupplier";

interface Props {
  type: ColRowType;
  showMedia?: boolean;
}

function InfoBlock(props: Props) {
  const { type, showMedia = true } = props;
  const { t } = useTranslation("common");
  const { getRoute } = useRoutesSupplier();

  const submit = () => {
    trackEvent("know_gudog_find_sitter_clicked");
    window.open(getRoute("search"), "_blank", "noopener,noreferrer");
  };

  const containerStyle = {
    ...styles.container,
    height: "100%",
    justifyContent: "space-between",
  };
  const colWidth = type === "column" ? "100%" : "50%";
  const colHeight = type === "column" ? "100%" : "";
  const buttonItem = (
    <Button variant="primary" size="large" width="100%" onClick={submit}>
      {t("infoBtn")}
    </Button>
  );

  return (
    <Flex sx={{ flexDirection: type, gap: "32px", ...containerStyle }}>
      <Flex sx={{ flexDirection: "column", gap: "16px", width: colWidth }}>
        <Text as="h2" sx={styles.title}>
          {t("knowGudogTitle")}
        </Text>

        <Text as="p" sx={styles.description}>
          {t("knowGudogDesc")}
        </Text>

        {type === "row" && buttonItem}
      </Flex>

      <Flex
        sx={{
          flexDirection: "column",
          gap: "12px",
          width: colWidth,
          height: colHeight,
          alignItems: "center",
        }}
      >
        {showMedia && (
          <Box sx={{ width: "100%", height: "100%" }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/TWleSUJ3-kk?enablejsapi=1"
              title={t("knowGudogTitle")}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </Box>
        )}
        {type === "column" && buttonItem}
      </Flex>
    </Flex>
  );
}

export default InfoBlock;
