import { Flex, Text, Box } from "rebass/styled-components";
import Button from "../common/Button";
import styles from "../../styles/subscribeBlockStyle";
import theme from "../../styles/theme";
import useTranslation from "next-translate/useTranslation";
import { ColRowType } from "../../types";
import Checkbox from "../common/Checkbox";
import { useState } from "react";
import useRoutesSupplier from "../../hooks/useRoutesSupplier";
import CustomOutsideLink from "../common/CustomOutsideLink";
import { trackEvent } from "../../lib/event-tracking";

function SubscribeBlock({ type }: { type: ColRowType }) {
  const { t } = useTranslation("common");
  const { getRoute } = useRoutesSupplier();

  const utmParams = `?utm_source=blog&utm_medium=banner&utm_campaign=signup_coupon`;
  const path = getRoute("signup") + utmParams;

  const submit = () => {
    trackEvent("signup_clicked", { origin: origin });
  };

  const colWidth = type === "column" ? "100%" : "50%";
  const justifyContent = type === "column" ? undefined : "space-between";
  const alignItems = type === "column" ? undefined : "center";

  return (
    <Flex
      sx={{
        flexDirection: type,
        gap: "16px",
        ...styles.container,
        justifyContent,
        alignItems,
      }}
    >
      <Flex sx={{ flexDirection: "column", width: colWidth, gap: "8px" }}>
        <Text as="h2" sx={{ ...theme.title, color: "samoyedWhite" }}>
          {t("subscribeTitle")}
        </Text>

        <Text as="p" sx={styles.subtitle}>
          {t("subscribeSubtitle")}
        </Text>
      </Flex>

      <CustomOutsideLink path={path}>
        <Button
          sx={styles.button}
          variant="secondary"
          size="large"
          onClick={submit}
        >
          {t("subscribeBtn")}
        </Button>
      </CustomOutsideLink>
    </Flex>
  );
}

export default SubscribeBlock;
