import useTranslation from "next-translate/useTranslation";
import { Flex, Box, Text } from "rebass/styled-components";
import { useContext } from "react";
import { LocaleContext } from "../locale";
import styles from "../../styles/footerStyle";
import CustomOutsideLink from "../common/CustomOutsideLink";
import useRoutesSupplier from "../../hooks/useRoutesSupplier";

interface Props {
  isDesktop: boolean;
}

const Areas = ({ isDesktop }: Props) => {
  const { t } = useTranslation("common");
  const { getServiceRoute } = useRoutesSupplier();

  const { country } = useContext(LocaleContext);

  const ie = [
    { path: "dublin", label: "Dublin" },
    { path: "cork", label: "Cork" },
    { path: "galway", label: "Galway" },
    { path: "limerick", label: "Limerick" },
    { path: "sligo", label: "Sligo" },
    { path: "wicklow", label: "Wicklow" },
    { path: "wexford", label: "Wexford" },
    { path: "killarney", label: "Killarney" },
    { path: "kildare", label: "Kildare" },
    { path: "kilkenny", label: "Kilkenny" },
    ...(isDesktop ? [] : []),
  ];

  const gb = [
    { path: "london", label: "London" },
    { path: "liverpool", label: "Liverpool" },
    { path: "belfast", label: "Belfast" },
    { path: "glasgow", label: "Glasgow" },
    { path: "birmingham", label: "Birmingham" },
    { path: "edinburgh", label: "Edinburgh" },
    { path: "wirral", label: "Wirral" },
    { path: "manchester", label: "Manchester" },
    { path: "bristol", label: "Bristol" },
    { path: "lisburn", label: "Lisburn" },
    ...(isDesktop ? [] : []),
  ];

  const es = [
    { path: "madrid", label: "Madrid" },
    { path: "barcelona", label: "Barcelona" },
    { path: "sevilla", label: "Sevilla" },
    { path: "valencia", label: "Valencia" },
    { path: "zaragoza", label: "Zaragoza" },
    { path: "malaga", label: "Málaga" },
    { path: "granada", label: "Granada" },
    { path: "cordoba", label: "Córdoba" },
    { path: "bilbao", label: "Bilbao" },
    { path: "gijon", label: "Gijón" },
    ...(isDesktop ? [] : []),
  ];

  const fr = [
    { path: "paris", label: "Paris" },
    { path: "lyon", label: "Lyon" },
    { path: "toulouse", label: "Toulouse" },
    { path: "bordeaux", label: "Bordeaux" },
    { path: "marseille", label: "Marseille" },
    { path: "lille", label: "Lille" },
    { path: "nice", label: "Nice" },
    { path: "nantes", label: "Nantes" },
    { path: "montpellier", label: "Montpellier" },
    { path: "rennes", label: "Rennes" },
    ...(isDesktop ? [] : []),
  ];

  const de = [
    { path: "berlin", label: "Berlin" },
    { path: "hamburg", label: "Hamburg" },
    { path: "munchen", label: "München" },
    { path: "frankfurt-am-main", label: "Frankfurt am Main" },
    { path: "koln", label: "Köln" },
    { path: "leipzig", label: "Leipzig" },
    { path: "nurnberg", label: "Nürnberg" },
    { path: "hannover", label: "Hannover" },
    { path: "dusseldorf", label: "Düsseldorf" },
    { path: "munster", label: "Münster" },
    ...(isDesktop ? [] : []),
  ];

  const dk = [
    { path: "kobenhavn", label: "København" },
    { path: "aarhus", label: "Aarhus" },
    { path: "odense", label: "Odense" },
    { path: "aalborg", label: "Aalborg" },
    { path: "esbjerg", label: "Esbjerg" },
    { path: "randers", label: "Randers" },
    { path: "kolding", label: "Kolding" },
    { path: "horsens", label: "Horsens" },
    { path: "vejle", label: "Vejle" },
    { path: "roskilde", label: "Roskilde" },
    ...(isDesktop ? [] : []),
  ];

  const no = [
    { path: "oslo", label: "Oslo" },
    { path: "bergen", label: "Bergen" },
    { path: "trondheim", label: "Trondheim" },
    { path: "stavanger", label: "Stavanger" },
    { path: "sandvika", label: "Sandvika" },
    { path: "kristiansand", label: "Kristiansand" },
    { path: "drammen", label: "Drammen" },
    { path: "asker", label: "Asker" },
    { path: "lillestrom", label: "Lillestrøm" },
    { path: "fredrikstad", label: "Fredrikstad" },
    ...(isDesktop ? [] : []),
  ];

  const se = [
    { path: "stockholm", label: "Stockholm" },
    { path: "goteborg", label: "Göteborg" },
    { path: "malmo", label: "Malmö" },
    { path: "uppsala", label: "Uppsala" },
    { path: "solentuna", label: "Solentuna" },
    { path: "sodermalm", label: "Södermalm" },
    { path: "vasterås", label: "Västerås" },
    { path: "orebro", label: "Örebro" },
    { path: "linkoping", label: "Linköping" },
    { path: "helsingborg", label: "Helsingborg" },
    ...(isDesktop ? [] : []),
  ];

  const areas = {
    gb: gb,
    ie: ie,
    es: es,
    de: de,
    fr: fr,
    dk: dk,
    se: se,
    no: no,
  };

  const currentCountryAreas = areas[country as unknown as keyof typeof areas];

  const columns = [
    {
      title: t("findDogSitters"),
      linkTitle: (city: string) => t("serviceTypeDefaultIn") + " " + city,
      link: (city: string) =>
        `${getServiceRoute("dog-boarding")}-${city.toLowerCase()}`,
    },
    {
      title: t("serviceType1"),
      linkTitle: (city: string) => t("serviceType1In", { city }),
      link: (city: string) =>
        `${getServiceRoute("dog-boarding")}-${city.toLowerCase()}`,
    },
    {
      title: t("serviceType2"),
      linkTitle: (city: string) => t("serviceType2In", { city }),
      link: (city: string) =>
        `${getServiceRoute("dog-walking")}-${city.toLowerCase()}`,
    },
    {
      title: t("serviceType7"),
      linkTitle: (city: string) => t("serviceType7In", { city }),
      link: (city: string) =>
        `${getServiceRoute("dog-daycare")}-${city.toLowerCase()}`,
    },
  ];

  return (
    <Box sx={{ px: [3, 3, 100], py: [5, 5, 8], backgroundColor: "lightGray" }}>
      <Text as="h2" variant="h2" sx={{ pb: 3 }}>
        {t("popularSearches")}
      </Text>

      <Flex width="100%" sx={styles.areas.container}>
        {columns.map(({ title, linkTitle, link }, index) => (
          <Box key={index} sx={styles.areas.column}>
            <Text as="h3" sx={styles.areas.title}>
              {title}
            </Text>

            {currentCountryAreas.map((area, index) => {
              return (
                <CustomOutsideLink key={index} path={link(area.path)}>
                  <Text as="h3" sx={styles.areas.link}>
                    {linkTitle(area.label)}
                  </Text>
                </CustomOutsideLink>
              );
            })}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default Areas;
