import { useContext } from "react";
import { LocaleContext } from "../components/locale";

function useRoutesSupplier() {
  const { country } = useContext(LocaleContext);

  const gudogSite =
    process.env.gudog &&
    process.env.gudog[country as unknown as keyof typeof process.env.gudog];

  const serviceRoutes =
    process.env.serviceRoutes &&
    process.env.serviceRoutes[
      country as unknown as keyof typeof process.env.serviceRoutes
    ];

  const routes =
    process.env.routes &&
    process.env.routes[country as unknown as keyof typeof process.env.routes];

  const helpRoute = ((process.env.help &&
    process.env.help[country as unknown as keyof typeof process.env.help]) ||
    "#") as string;

  const blogRoute = ((process.env.blog &&
    process.env.blog[country as unknown as keyof typeof process.env.blog]) ||
    "#") as string;

  const contactRoute = ((process.env.contact &&
    process.env.contact[
      country as unknown as keyof typeof process.env.contact
    ]) ||
    "#") as string;

  const getRoute = (route: string) => {
    return `${gudogSite}${routes ? routes[route as keyof typeof routes] : ""}`;
  };

  const getServiceRoute = (service: string) => {
    return `${gudogSite}/${
      serviceRoutes ? serviceRoutes[service as keyof typeof serviceRoutes] : ""
    }`;
  };

  return { getRoute, getServiceRoute, helpRoute, blogRoute, contactRoute };
}

export default useRoutesSupplier;
