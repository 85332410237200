const styles = {
  areas: {
    container: {
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    column: {
      width: ["100%", "50%", "50%", "50%", "25%"],
      pb: 2,
      pr: 4,
    },
    title: {
      pb: 1,
      fontSize: 3,
      fontWeight: 500,
    },
    link: {
      color: "springGreen",
      fontSize: 3,
      lineHeight: "24px",
      fontWeight: 500,
    },
  },
  container: {
    flexWrap: "wrap",
    backgroundColor: "prusianBlue",
    color: "samoyedWhite",
    top: "100%",
    px: [3, 3, 100],
    py: [5, 5, 8],
    width: "100%",
  },
  titleColumn: {
    fontSize: "16px",
    fontWeight: 500,
    mb: 1,
  },
  linkColumn: {
    pb: 1,
    cursor: "pointer",
    fontWeight: 400,
  },
  line: {
    width: "100%",
    height: "1px",
    mt: 2,
    mb: 2,
    backgroundColor: "#DDDDDD",
  },
  logoCopyrightContainer: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  copyrightText: {
    color: "darkGray",
    float: "right",
    fontSize: 1,
    letterSpacing: "body",
  },
  policyText: {
    fontSize: 0,
    color: "darkGray",
    a: { color: "darkGray" },
  },
};

export default styles;
