const styles = {
  container: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    zIndex: 5,
    background: "transparent",
    position: "absolute",
    top: 0,
    px: "48px",
    py: "16px",
    color: "labradorBlack",
    borderBottom: "1px solid rgb(221, 221, 221)",
  },
  siteDescription: {
    color: "inuOrange",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
    mt: "-6px",
  },
  headerIcon: {},
  itemsContainer: {
    gap: "14px",
  },
  navItem: {
    pl: 4,
    display: "inline",
    fontWeight: 600,
    color: "labradorBlack",
  },
  searchInput: {
    fontWeight: 600,
    p: 0,
    flex: 1,
    color: "gray",
    outline: "none",
    borderColor: "transparent",
    boxShadow: "none",
    "::placeholder": {
      color: "lightGray",
    },
  },
  searchText: { pt: "3px", pr: 1, fontWeight: 600 },
  categoriesContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    mt: 2,
    py: "12px",
    mx: ["16px", "16px", "120px"],
  },
  categoriesTitle: {
    fontWeight: 600,
    color: "prusianBlue",
    textDecoration: "underline !important",
    px: "12px",
  },
  searchBox: {
    p: "8px 12px",
    gap: "8px",
    border: "1px solid",
    borderColor: "rgb(235, 235, 235)",
    borderRadius: "30px",
    bg: "samoyedWhite",
    alignItems: "center",
  },
  iconButton: {
    bg: "samoyedWhite",
    p: "0px",
    "&:hover": { borderRadius: "16px" },
  },
  searchIcon: {
    borderRadius: "16px",
    justifyContent: "center",
    alignItems: "center",
    bg: "inuOrange",
  },
  socialMedia: {
    justifyContent: "center",
    alignItems: "center",
  },
  socialMediaIcon: {
    bg: "springGreen",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
  },
  menu: {
    maxHeight: 0,
    opacity: 0,
    overflow: "hidden",
    transition: "all 0.5s ease-in-out",
  },
  menuVisible: {
    maxHeight: "1050px",
    opacity: 1,
    transition: "all 0.5s ease-in-out",
    mb: "16px",
  },
  subMenu: {
    maxHeight: 0,
    opacity: 0,
    transition: "all 0.5s ease-in-out",
    overflow: "hidden",
    flexDirection: "column",
    gap: "16px",
    my: 0,
  },
  subMenuVisible: {
    maxHeight: "180px",
    opacity: 1,
    transition: "all 0.5s ease-in-out",
    flexDirection: "column",
    gap: "16px",
    mt: 2,
    mb: 1,
  },
  search: {
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.5s ease-in-out",
  },
  searchBoxVisible: {
    maxHeight: "200px",
    transition: "max-height 0.5s ease-in-out",
  },
};

export default styles;
