import { Flex, Box, Text, Image } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import { LocaleContext } from "../locale";
import Trans from "next-translate/Trans";
import { publicRuntimeConfig } from "../../lib/react-gtm";
import Areas from "./Areas";
import styles from "../../styles/footerStyle";
import CustomOutsideLink from "../common/CustomOutsideLink";
import Icon from "../common/Icon";
import useRoutesSupplier from "../../hooks/useRoutesSupplier";

const ColumnBox = ({ children }: { children: any }) => {
  return (
    <Box width={[1, 1 / 5, 1 / 5]} sx={{ pb: [3, 3, 0] }}>
      {children}
    </Box>
  );
};

type HrefLangType = {
  href: string;
  locale: string;
  country: string;
};

interface Props {
  isMobile?: boolean;
  displayAreas?: boolean;
}

const Footer = ({ isMobile, displayAreas }: Props) => {
  const { locale, country } = useContext(LocaleContext);
  const { t } = useTranslation("common");
  const { getRoute, getServiceRoute, helpRoute, blogRoute, contactRoute } =
    useRoutesSupplier();

  // Variables
  const language = (locale ?? "en").substring(0, 2);
  const hrefLangAlternate: HrefLangType[] =
    publicRuntimeConfig.hrefLangAlternate;

  const googlePlayLink =
    "https://play.google.com/store/apps/details?id=com.gudog.app";
  const appStoreLink = `https://apps.apple.com/${country}/app/gudog/id964470204`;

  const renderLink = (path: string, title: string, style?: any) => {
    return (
      <CustomOutsideLink path={path}>
        <Text as="p" sx={{ ...styles.linkColumn, ...style }}>
          {t(title)}
        </Text>
      </CustomOutsideLink>
    );
  };

  const renderRouteLink = (route: string, title: string) => {
    const path = getRoute(route);
    return renderLink(path, title);
  };

  const renderServiceLink = (service: string, title: string) => {
    const path = getServiceRoute(service);
    return renderLink(path, title);
  };

  return (
    <Box as="footer" width="100%">
      {displayAreas && <Areas isDesktop={!isMobile} />}

      <Flex sx={styles.container}>
        {/* First Column */}
        <ColumnBox>
          <Text as="p" sx={styles.titleColumn}>
            {t("gudog")}
          </Text>

          {renderRouteLink("ourservices", "ourServices")}
          {renderRouteLink("howitworks", "howItWorks")}
          {renderRouteLink("about", "aboutGudog")}
          {renderRouteLink("testimonials", "testimonials")}
          {renderRouteLink("veterinarycover", "veterinarycover")}
          {renderRouteLink("adviceforowners", "tipsForDogOwners")}
          {renderRouteLink("adviceforsitters", "tipsForDogSitters")}
          {renderRouteLink("recruitingsitters", "becomeDogSitter")}

          <CustomOutsideLink path={helpRoute}>
            <Text as="p" sx={styles.linkColumn}>
              {t("help")}
            </Text>
          </CustomOutsideLink>

          <CustomOutsideLink path={blogRoute}>
            <Text as="p" sx={styles.linkColumn}>
              Blog
            </Text>
          </CustomOutsideLink>

          <CustomOutsideLink path={contactRoute}>
            <Text as="p" sx={styles.linkColumn}>
              {t("contact")}
            </Text>
          </CustomOutsideLink>
        </ColumnBox>

        {/* Second Column */}
        <ColumnBox>
          <Text as="p" sx={styles.titleColumn}>
            {t("ourServices")}
          </Text>

          {renderServiceLink("dog-daycare", "dogDayCare")}
          {renderServiceLink("dog-walking", "dogWalking")}
          {renderServiceLink("dog-boarding", "boarding")}

          {(country === "dk" || country === "no" || country === "se") &&
            renderServiceLink(
              getServiceRoute("house-sitting"),
              "house-sitting"
            )}
        </ColumnBox>

        {/* Third Column */}
        <ColumnBox>
          <Text as="p" sx={styles.titleColumn}>
            {t("countries")}
          </Text>

          {hrefLangAlternate.map((hrefLang, index) => {
            return (
              hrefLang.country &&
              country != hrefLang.country && (
                <CustomOutsideLink path={hrefLang.href}>
                  <Text as="p" sx={styles.linkColumn}>
                    {t(hrefLang.locale)}
                  </Text>
                </CustomOutsideLink>
              )
            );
          })}
        </ColumnBox>

        {/* Fourth Column */}
        <ColumnBox>
          {renderRouteLink("terms", "tAndC")}
          {renderRouteLink("privacy", "privacyPolicy")}

          <Flex width={1} sx={{ gap: 2, py: 1 }}>
            <CustomOutsideLink path={t("instagramUrl")}>
              <Icon name="instagram" color="samoyedWhite" fontSize={5}></Icon>
            </CustomOutsideLink>

            <CustomOutsideLink path={t("facebookUrl")}>
              <Icon name="facebook" color="samoyedWhite" fontSize={6}></Icon>
            </CustomOutsideLink>

            <CustomOutsideLink path={t("twitterUrl")}>
              <Icon name="twitter" color="samoyedWhite" fontSize={5}></Icon>
            </CustomOutsideLink>
          </Flex>
        </ColumnBox>

        {/* Fifth Column */}
        <ColumnBox>
          <Text as="p" sx={styles.titleColumn}>
            {t("downloadOurApp")}
          </Text>
          <Box width={1} sx={{ pt: 1 }}>
            <CustomOutsideLink path={googlePlayLink}>
              <Image
                alt="Gudog - Google Play"
                src={`/blog/images/locale/${language}/google_play.svg`}
                width={"auto"}
                height={"49.5px"}
              />
            </CustomOutsideLink>
          </Box>

          <Box width={1} sx={{ pt: 1 }}>
            <CustomOutsideLink path={appStoreLink}>
              <Image
                alt="Gudog - App Store"
                src={`/blog/images/locale/${language}/app_store.svg`}
                width={"auto"}
                height={"49.5px"}
              />
            </CustomOutsideLink>
          </Box>
        </ColumnBox>

        {/* Bottom part */}
        <Box sx={styles.line}></Box>

        <Flex sx={styles.logoCopyrightContainer}>
          <Box>
            <Icon
              name="gudogLogo"
              color="samoyedWhite"
              fontSize={9}
              height="16px"
            />
          </Box>

          <Box>
            <Text sx={styles.copyrightText}>
              {t("copyright", { year: new Date().getFullYear() })}
            </Text>
          </Box>
        </Flex>

        <Box width={1} sx={{ mt: 1 }}>
          <Text sx={styles.policyText}>
            <Trans
              i18nKey="common:recaptcha"
              components={[
                <a
                  key={0}
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                />,
                <a
                  key={1}
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noreferrer noopener"
                />,
              ]}
            />
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
