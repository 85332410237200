import { Flex, Text, Box } from "rebass/styled-components";
import Icon from "../common/Icon";
import styles from "../../styles/heroStyle";
import useTranslation from "next-translate/useTranslation";
import Button from "../common/Button";
import CustomLink from "../common/CustomLink";

interface Props {
  title: string;
  subTitle: string;
  isMobile: boolean;
  onMenuClick: () => void;
  onSearchClick: () => void;
}

function HeroTitle(props: Props) {
  const { subTitle, isMobile, onMenuClick, onSearchClick } = props;

  const containerStyle = {
    ...styles.heroTitle.container,
    ...(isMobile && { bg: "inuOrange" }),
  };

  return (
    <Flex sx={containerStyle}>
      <CustomLink href="/">
        <Flex sx={{ flexDirection: "column", ...styles.heroTitle.stamp }}>
          <Icon name={"gublogWhite"} fontSize={1} height="40px" width="100px" />

          <Text as="h2" sx={styles.heroTitle.subTitle}>
            {subTitle}
          </Text>
        </Flex>
      </CustomLink>

      {isMobile && (
        <Box>
          <Button sx={styles.heroTitle.button} onClick={onSearchClick}>
            <Icon name="search" color="samoyedWhite" fontSize={6} />
          </Button>

          <Button sx={styles.heroTitle.button} onClick={onMenuClick}>
            <Icon name="burger" color="samoyedWhite" fontSize={6} />
          </Button>
        </Box>
      )}
    </Flex>
  );
}

export default HeroTitle;
