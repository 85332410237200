import React from "react";
import { Flex, Box } from "rebass/styled-components";
import Image from "next/image";
import CoverImage from "../common/CoverImage";
import styles from "../../styles/heroStyle";

interface Props {
  children: any;
  variant: string;
  imgsrc: string;
  imgAlt?: string;
  darken: number;
}

export default function Hero(props: Props) {
  const { children, variant, imgsrc, imgAlt, darken } = props;
  const containerStyle = {
    ...styles.container[variant as keyof typeof styles.container],
  };

  return (
    <Flex sx={containerStyle}>
      {/* <CoverImage
        src={imgsrc}
        shouldLazyLoad={false}
        priority
        darken={darken}
      /> */}
      <Box width="100%" backgroundColor="white">
        {/* <Flex sx={styles.children}>{children}</Flex> */}
      </Box>
    </Flex>
  );
}

Hero.defaultProps = {
  variant: "default",
  darken: 0.25,
};
