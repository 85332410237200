import { Box, Flex, Text } from "rebass/styled-components";
import styles from "../../styles/navigationMenuStyle";
import useTranslation from "next-translate/useTranslation";
import CustomLink from "../common/CustomLink";
import SearchBox from "./SearchBox";
import NavItem from "./NavItem";
import Icon from "../common/Icon";
import { MenuLinksType } from "../../types";
import CustomOutsideLink from "../common/CustomOutsideLink";

interface Props {
  menuLinks: MenuLinksType;
}

function NavigationMenu({ menuLinks }: Props) {
  const { t } = useTranslation("common");

  const NavItems = () => {
    const displayStyle = ["block", "none", "none", "none", "block"];

    return (
      <Flex sx={styles.itemsContainer}>
        {Object.keys(menuLinks).map((site, index) => {
          const style = site !== "login" && { display: displayStyle };
          const href = menuLinks[site as keyof typeof menuLinks];

          return (
            <NavItem key={index} sx={style}>
              <CustomOutsideLink path={href}>{t(site)}</CustomOutsideLink>
            </NavItem>
          );
        })}
      </Flex>
    );
  };

  return (
    <Flex sx={styles.container}>
      <Box flex={1}>
        <CustomLink href="/">
          <Box>
            <Icon
              name={"gublogOrange"}
              fontSize={1}
              height="40px"
              width="100px"
            />
            <Text as="h2" sx={styles.siteDescription}>
              {t("siteDescription")}
            </Text>
          </Box>
        </CustomLink>
      </Box>

      <SearchBox />

      <NavItems />
    </Flex>
  );
}
export default NavigationMenu;
