const styles = {
  container: {
    border: "2px solid",
    borderColor: "inuOrange",
    borderRadius: "8px",
    p: 3,
  },
  imgBox: {
    position: "relative",
    height: ["36vw", "36vw", "15vw"],
  },
  title: {
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 400,
    color: "inuOrange",
  },
};

export default styles;
