import Image from "next/image";
import { Flex, Text, Box } from "rebass/styled-components";
import Button from "../common/Button";
import theme from "../../styles/theme";
import styles from "../../styles/findSitterStyle";
import useTranslation from "next-translate/useTranslation";
import { trackEvent } from "../../lib/event-tracking";
import { PageOrigin } from "../../types";
import useRoutesSupplier from "../../hooks/useRoutesSupplier";
import CustomOutsideLink from "../common/CustomOutsideLink";

function FindSitter({
  origin,
  slim = false,
}: {
  origin: PageOrigin;
  slim?: boolean;
}) {
  const { t } = useTranslation("common");
  const { getRoute } = useRoutesSupplier();
  const imgSrc = "/blog/images/services_header_desktop.webp";

  const utmParams = `?utm_source=blog&utm_medium=banner&utm_campaign=signup_coupon`;
  const path = getRoute("search") + utmParams;

  const submit = () => {
    trackEvent("find_sitter_clicked", { origin: origin });
  };

  return (
    <Flex sx={{ flexDirection: "column", ...styles.container, gap: "12px" }}>
      {!slim && (
        <Box width="100%" sx={styles.imgBox}>
          <Image src={imgSrc} loading="lazy" objectFit="cover" layout="fill" />
        </Box>
      )}

      <Text as="h4" sx={{ ...theme.title, color: "inuOrange" }}>
        {t("findSitterTitle")}
      </Text>

      <CustomOutsideLink path={path}>
        <Button
          variant="primary"
          size="large"
          sx={{ width: "100%" }}
          onClick={submit}
        >
          {t("findSitterBtn")}
        </Button>
      </CustomOutsideLink>
    </Flex>
  );
}

export default FindSitter;
