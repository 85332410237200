import { Flex, Text, Box, Button } from "rebass/styled-components";
import theme from "../../styles/theme";
import styles from "../../styles/navigationMenuStyle";
import InfoBlock from "../promotion/InfoBlock";
import Icon from "../common/Icon";
import { CategoryInfoMenu, MenuLinksType } from "../../types";
import CustomLink from "../common/CustomLink";
import SearchBox from "./SearchBox";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import CustomOutsideLink from "../common/CustomOutsideLink";

interface Props {
  categories: CategoryInfoMenu[];
  menuLinks: MenuLinksType;
  menuVisible: boolean;
  searchVisible: boolean;
  onSearch: () => void;
}

function NavigationMenuMobile(props: Props) {
  const { categories, menuLinks, menuVisible, searchVisible, onSearch } = props;
  const { t } = useTranslation("common");

  const [subMenuVisible, setSubMenuVisible] = useState(false);

  const menuStyle = menuVisible ? styles.menuVisible : styles.menu;
  const subMenuStyle = subMenuVisible ? styles.subMenuVisible : styles.subMenu;
  const searchBoxStyle = searchVisible
    ? styles.searchBoxVisible
    : styles.search;

  const submit = (href: string) => {
    window.open(href, "_blank", "noopener,noreferrer");
  };

  const getBorder = (noBorder: boolean) => {
    return noBorder ? "none" : "1px solid lightGray";
  };

  const renderMenuItems = () => {
    return categories.map((cat, index) => {
      const isLastItem = index === categories.length - 1;
      return (
        <CustomLink href={cat.path} key={index}>
          <Box sx={{ p: "16px", borderBottom: getBorder(isLastItem) }}>
            <Text as="h3" sx={theme.title}>
              {cat.label}
            </Text>
          </Box>
        </CustomLink>
      );
    });
  };

  const renderSubMenuItems = () => {
    return Object.keys(menuLinks).map((site, index) => {
      const href = menuLinks[site as keyof typeof menuLinks];
      return (
        <CustomOutsideLink key={index} path={href}>
          <Text sx={{ pl: 4 }}>{t(site)}</Text>
        </CustomOutsideLink>
      );
    });
  };

  return (
    <>
      <Box sx={menuStyle}>
        <Flex sx={{ flexDirection: "column", p: "16px" }}>
          <Flex
            sx={{
              justifyContent: "space-between",
              p: "16px",
              borderBottom: getBorder(false),
            }}
            onClick={() => setSubMenuVisible(!subMenuVisible)}
          >
            <Text as="h3" sx={theme.title}>
              {t("aboutGudog")}
            </Text>

            <Flex sx={{ alignItems: "center" }}>
              <Icon
                name={subMenuVisible ? "caret-up" : "caret-down"}
                color={"springGreen"}
                fontSize={4}
              />
            </Flex>
          </Flex>

          <Flex sx={subMenuStyle}>{renderSubMenuItems()}</Flex>

          <Box sx={{ pb: "16px" }}>{renderMenuItems()}</Box>

          <InfoBlock type="column" showMedia={false} />

          <Flex sx={{ gap: "16px", ...styles.socialMedia }}>
            <Button
              sx={{ bg: "samoyedWhite" }}
              onClick={() => submit(t("instagramUrl"))}
            >
              <Flex width="48px" height="48px" sx={styles.socialMediaIcon}>
                <Icon name="instagram" color={"samoyedWhite"} fontSize={5} />
              </Flex>
            </Button>

            <Button
              sx={{ bg: "samoyedWhite" }}
              onClick={() => submit(t("facebookUrl"))}
            >
              <Icon name="facebook-filled" color={"springGreen"} fontSize={8} />
            </Button>

            <Button
              sx={{ bg: "samoyedWhite" }}
              onClick={() => submit(t("twitterUrl"))}
            >
              <Flex width="48px" height="48px" sx={styles.socialMediaIcon}>
                <Icon name="twitter" color={"samoyedWhite"} fontSize={5} />
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>

      <Box sx={searchBoxStyle}>
        <Box sx={{ p: "16px 16px 0 16px" }}>
          <SearchBox onSearch={onSearch} />
        </Box>
      </Box>
    </>
  );
}

export default NavigationMenuMobile;
