const styles = {
  container: {
    border: "2px solid",
    borderColor: "inuOrange",
    borderRadius: "8px",
    p: 3,
  },
  title: {
    width: ["60%", "60%", "100%"],
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 500,
    color: "inuOrange",
  },
  description: {
    fontSize: "18px",
    lineHeight: "30px",
    fontWeight: 500,
  },
  videoBox: {
    height: ["176px", "100%", "100%"],
    bg: "lightGray",
  },
};

export default styles;
