/* With this component we send the user to another tab
outside of the blog */
import NextLink from "next/link";
import styled from "styled-components";

interface Props {
  path: string;
  children: JSX.Element;
}

const AnchorLink = styled.a`
  text-decoration: none !important;
  color: inherit;
`;

function CustomOutsideLink(props: Props) {
  const { path, children } = props;

  return (
    <AnchorLink href={path} target="_blank">
      {children}
    </AnchorLink>
  );
}

export default CustomOutsideLink;
