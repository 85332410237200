import { useEffect, useState } from "react";

/* We are using this hook to know if the user is using a mobile device.
We tried to do it server-side with withDeviceType (as it is done in the web),
but due to faust configuration it was not possible to make it work.
This way, we are getting to know client-side it the user is on a mobile device.
The main drawback is that we are sending both renderings (desktop and mobile) to the server. */

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
