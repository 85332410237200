import { Flex, Text, Box } from "rebass/styled-components";
import { CategoryInfoMenu } from "../../types";
import CustomLink from "../common/CustomLink";
import theme from "../../styles/theme";
import styles from "../../styles/navigationMenuStyle";

function CategoriesHeader({ categories }: { categories: CategoryInfoMenu[] }) {
  return (
    <Flex sx={styles.categoriesContainer}>
      {categories.map((cat, index) => (
        <CustomLink key={index} href={cat.path}>
          <Text
            as="h4"
            sx={{ ...theme.description, ...styles.categoriesTitle }}
          >
            {cat.label}
          </Text>
        </CustomLink>
      ))}
    </Flex>
  );
}

export default CategoriesHeader;
