import { Box, Flex } from "rebass/styled-components";
import Icon from "../common/Icon";
import { Text } from "rebass/styled-components";
import styles from "../../styles/navigationMenuStyle";
import useTranslation from "next-translate/useTranslation";
import useRoutesSupplier from "../../hooks/useRoutesSupplier";

function SearchBox({ onSearch }: { onSearch?: () => void }) {
  const { getRoute } = useRoutesSupplier();
  const { t } = useTranslation("common");

  return (
    <a href={getRoute("search")} style={{ textDecoration: "none" }}>
      <Flex sx={styles.searchBox}>
        <Box sx={styles.iconButton}>
          <Flex width="32px" height="32px" sx={styles.searchIcon}>
            <Icon name="search" color="samoyedWhite" fontSize={2} />
          </Flex>
        </Box>

        <Text sx={styles.searchText}>{t("searchPlaceholder")}</Text>
      </Flex>
    </a>
  );
}

export default SearchBox;
